
.cost{
    text-align: right;
}
table{
    display: block ;    
    width: 800px;
    max-width: 95vw;
    margin: 1rem auto;
  }
  
  td{
    color: white;
    padding: 10px;
    text-align: left;
    width: 50%;
    display: inline-block;
  }
  
  th{
    color: white;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    width: 50%;
    display: inline-block;

  }

  thead, tbody, tr{
    display: block;
    width: 100%;
  }

@media only screen and (max-width: 600px) {
    td{
        font-size: 0.8rem;

    }
  }
