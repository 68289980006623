#services{
    min-height: 50vh;
    background-color: #7a9b96;
    color: #fff;
    padding: 1rem 0 2rem 0 ;
}

.service-list{
    width: 1000px;
    max-width: 90vw;
    margin: 0 auto;
    text-align: left;

}

li{
    margin: 1rem 0 0 -40px;
    list-style-type: none;
}
