#why-us{
    padding: 2rem;
    justify-content: center;
    align-items: center;
}

#why-us ul{
    width: 800px;
    max-width: 90vw;
    text-align: left;
    margin: 1rem auto
}

#why-us p{
    width: 1000px;
    max-width: 90vw;
    text-align: left;
    margin: 0 auto;
}