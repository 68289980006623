.App {
  text-align: center;
}

html{
  scroll-behavior: smooth;
}

@font-face {
  font-family: Minion;
  src: url(./fonts/MinionVariableConcept-Roman.otf);
  font-display: fallback;
}

@font-face {
  font-family: Basskia;
  src: url(./fonts/Basskia.ttf);
  font-display: fallback;
}

@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham-Light.otf);
  font-display: fallback;
}

@font-face {
  font-family: GothamBold;
  src: url(./fonts/Gotham-Bold.otf);
  font-display: fallback;
}

*{
  box-sizing: border-box;
}

p, li, td{
  font-family: Gotham, sans-serif;
  line-height: 25px;
}

b, h4, th{
  font-family: GothamBold, sans-serif;
  line-height: 25px;
}

#hero{
  position: relative;
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('./assets/horse-small.png');
  background-position: 15% 100%;
  background-size: cover;
  width: 100vw;
  min-height: 95vh;
}

#btn{
  background-color: #7a9b96;
  color: white;
  border: unset;
  letter-spacing: 3px;
  border-radius: 5px;
  width: auto;
  height: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;  
  font-weight: lighter !important;
  font-size: 10px;
  font-family: Minion;
  padding: 10px;
  text-transform: uppercase;

}

#horse-vid{

  width: 100vw;
  height: auto;
  min-height: 95vh;
  z-index: 0;
  object-fit: cover;
  object-position: 15% 100%;
}

#gradient{
  background: rgba(255,255,255, 0.5);
  background: linear-gradient(180deg, rgba(255,255,255,0.5) 98%, rgba(255,255,255,1) 100%);  
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav{
  display: flex;
  font-family: Minion;
  letter-spacing: 3px;
  justify-content:left;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 80px;
  font-weight: 900;
  font-size: 20px;
  text-transform: uppercase;
  padding: 1rem;
  color: #7a9d96;
  z-index: 3;
}

a{
  margin: 0 10px;
  font-size: 0.9rem;
  color: #282c34;
  text-decoration: none;
  font-weight: 100
}

#hero-logo{
  width: 500px;
  max-width: 90vw;
}

.section-header, h3{
  font-family: Basskia;
  font-size: 80px;
  font-weight: lighter;
  margin: 0 auto;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

footer{
  padding: 1rem;
}


@media only screen and (max-width: 600px) {
  nav {
    justify-content: center;
  }
  a{
    font-size: 0.7rem;
  }
}