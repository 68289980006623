#about-me{
    min-height: 50vh;
    padding: 2rem;
    color: #00303f;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
}

#about-img{
    height: 250px;
    width: 250px;
    object-position: center;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

#about-container{
    width: 600px;
    max-width: 90vw;
}

.tick-container{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.tick{
    height: 40px;
    margin-right: 1rem;
}